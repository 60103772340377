import { template as template_9e327b3c40ea462b9b12b58af8d19301 } from "@ember/template-compiler";
import PixLabel from '@1024pix/pix-ui/components/pix-label';
import PixRadioButton from '@1024pix/pix-ui/components/pix-radio-button';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { COMPLEMENTARY_KEYS } from 'pix-certif/models/subscription';
export default class CandidateCreationModalComplementaryList extends Component {
    @service
    currentUser;
    @service
    intl;
    fieldsetLegend = ()=>{
        if (this.currentUser.currentAllowedCertificationCenterAccess.isCoreComplementaryCompatibilityEnabled) {
            return this.intl.t(`common.forms.certification-labels.additional-certification`);
        }
        return this.intl.t(`common.forms.certification-labels.additional-certification-old`);
    };
    firstInputLabel = ()=>{
        if (this.currentUser.currentAllowedCertificationCenterAccess.isCoreComplementaryCompatibilityEnabled) {
            return this.intl.t('common.forms.certification-labels.pix-certification');
        }
        return this.intl.t('common.labels.candidate.none');
    };
    complementaryLabel = (complementaryCertificationHabilitation)=>{
        const { key, label } = complementaryCertificationHabilitation;
        if (this.currentUser.currentAllowedCertificationCenterAccess.isCoreComplementaryCompatibilityEnabled && key === COMPLEMENTARY_KEYS.CLEA) {
            return this.intl.t('common.forms.certification-labels.dual-certification-clea');
        }
        return label;
    };
    static{
        template_9e327b3c40ea462b9b12b58af8d19301(`
    <div class='new-candidate-modal-form__field'>
      <fieldset id='complementary-certifications'>
        <legend class='label'>
          <PixLabel @requiredLabel={{t 'common.forms.required'}}>
            {{this.fieldsetLegend}}
          </PixLabel>
        </legend>
        <PixRadioButton required name='subscriptions' {{on 'change' (fn @updateComplementaryCertification null)}}>
          <:label>{{this.firstInputLabel}}</:label>
        </PixRadioButton>
        {{#each @complementaryCertificationsHabilitations as |complementaryCertificationHabilitation|}}
          <PixRadioButton
            required
            name='subscriptions'
            {{on 'change' (fn @updateComplementaryCertification complementaryCertificationHabilitation)}}
          >
            <:label>{{this.complementaryLabel complementaryCertificationHabilitation}}</:label>
          </PixRadioButton>
        {{/each}}
      </fieldset>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
