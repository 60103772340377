import { template as template_f025cc83a63e40f8be089b9be9570759 } from "@ember/template-compiler";
import PixTabs from '@1024pix/pix-ui/components/pix-tabs';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_f025cc83a63e40f8be089b9be9570759(`
  <PixTabs @variant='certif' @ariaLabel={{t 'pages.sessions.detail.tabs.aria-label'}}>
    <LinkTo @route='authenticated.sessions.details.parameters'>
      {{t 'pages.sessions.detail.tabs.details'}}
    </LinkTo>
    <LinkTo @route='authenticated.sessions.details.certification-candidates'>
      {{t 'common.sessions.candidates'}}
      {{@certificationCandidatesCount}}
    </LinkTo>
  </PixTabs>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
