import { template as template_580b9db9d29146af9a9a6bde3c3f5158 } from "@ember/template-compiler";
export default template_580b9db9d29146af9a9a6bde3c3f5158(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
